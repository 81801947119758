.sidebar-toggle {
    top: 1rem;
    padding: 0.2rem;
    cursor: pointer;
    color: $black;
    .icon {
        display: flex;
        transform: rotate(0);
        transition: var(--sidebar-transition);
        transition-duration: var(--sidebar-transition-duration);
        transition-timing-function: var(--sidebar-transition-function-ease-in-out);
        .icon-20 {
            display: block;
            transition: transform;
            transition-duration: var(--sidebar-transition-duration);
            transition-timing-function: var(--sidebar-transition-function-ease-in-out);
            height: 1.25rem;
            width: 1.25rem;
        }
    }
}

@media (max-width: 1199.98PX) {
    .sidebar-toggle {
        right: 18px;
    }
}

