.iq-right-fixed {
	margin: 0 0.938rem;
}
.right-sidebar-mini {
	top: 4.75rem;
	z-index: 100;
	position: fixed;
	width: 16.25rem;
	right: 0;
	z-index: 1;
	transform: translateX(calc(111% + -2em));
	@if $enable-transitions == true {
		@include transition-mixin(all 0.3s ease-in-out);
	}	
	.side-left-icon {
		display: none;
	}
}
.right-sidebar-toggle {
	position: absolute;
	margin-left: -2.5rem;
	background: $white;
	padding: 0.5rem;
	display: inline;
	top:0;
	z-index: 99;
	@if $enable-rounded == true {
		@include radius-mixin(30px 0px 0px 30px);
	}
	@if $enable-shadows == true {
		@include shadow-mixin($box-shadow);
	}
	cursor: pointer;
}
.right-sidebar-panel {
	background-color: $white;
	@if $enable-shadows == true {
		@include shadow-mixin($box-shadow);
	}
	height: 100vh;
	padding: 0.938rem;
	.media-height {
		max-height: 98vh;
		overflow-y: scroll;
	}
}
.right-sidebar {
	transform: translateX(calc(10% + -1em));
	.side-right-icon {
		display: block;
	}
	.side-left-icon {
		display: none;
	}
	&.right-sidebar-mini{
		.side-right-icon {
			display: none;
		}
		.side-left-icon {
			display: block;
		}
	}
}
.offcanvas.live-customizer{
	z-index: 1111;

}


@include media-breakpoint-down(lg) {
	.right-sidebar-toggle {
		position: absolute;
		top: 0%;
	}
}